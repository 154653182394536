import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
// import Layout from './Layout';

const List = React.lazy(() => import('@pages/List'));

export default function Router() {
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        {/* <Route element={<Layout />}> */}
          <Route path="/list" element={<List />} />
          <Route path="/detail" element={<List />} />
        {/* </Route> */}
      </Routes>
    </Suspense>
  );
}
